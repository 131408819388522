import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
// import icon from '../images/icon.js';
// import fetch from '../fetch/axios';
// import logo from '../../images/logo.svg';
import '../../style/nav.less';
// import HeaderUsage from './usage'
// import ChatList from '../chat/chatList'

import ic_kb from '../../images/icon/ic_nav_kb.svg';
// import ic_kb_active from '../../images/icon/ic_nav_kb_active.svg';
import ic_bot from '../../images/icon/ic_nav_bot.svg';
// import ic_bot_active from '../../images/icon/ic_nav_bot_active.svg';
import ic_general from '../../images/icon/ic_nav_general.svg';
// import ic_general_active from '../../images/icon/ic_nav_general_active.svg';
import ic_rights from '../../images/icon/ic_nav_rights.svg';
// import ic_rights_active from '../../images/icon/ic_nav_rights_active.svg';

const { Sider } = Layout;

class NavigationBar extends React.Component {
  state = {
    pathname: "",
    selectedKeys: ['projects', 'dashboard'],
    role: "",
    lastOpen: ['square', 'dashboard'],
    search: "",
  }
  componentDidMount() {
    // console.log(this.props);
  }

static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
        return {
        }
    }
    if (props.user.role !== state.role) {
        return {
        }
    }
    return null
}

componentDidUpdate() {
    if (this.props.location.pathname !== this.state.pathname) {
        this.setState({ pathname: this.props.location.pathname }, () => {
            if (this.state.pathname === '/' || this.state.pathname === '/bot') {
                //if (this.state.pathname === '/') {
                this.props.history.push('/knowledge-base')
            } else {
                this.listenRoute(this.state.pathname);
            }
        })
    }
    if (this.props.user.role !== this.state.role) {
        this.setState({ role: this.props.user.role })
    }
}

listenRoute(path) {
    // console.log(path)
    if (path.includes('/knowledge-base')) {
        this.setState({ selectedKeys: ['kb'] })
    } else if (path.includes('/bots')) {
        this.setState({ selectedKeys: ['bot'] })
    } else if (path.includes('/general/prompt')) {
        this.setState({ selectedKeys: ['general', 'prompt'] })
    } else if (path.includes('/general/history')) {
        this.setState({ selectedKeys: ['general', 'history'] })
    } else if (path.includes('/general/azure-setting')) {
        this.setState({ selectedKeys: ['general', 'azureSetting'] })
    } else if (path.includes('/system/role')) {
        this.setState({ selectedKeys: ['rights', 'role'] })
    } else if (path.includes('/system/user')) {
        this.setState({ selectedKeys: ['rights', 'user'] })
    } else {
        this.setState({ selectedKeys: [] })
    }
}

changeNavPath(e) {
    // console.log(e);
    // console.log(this.props.history);
    if (e.key === 'kb') {
        this.props.history.push('/knowledge-base');
    } else if (e.key === 'bot') {
        this.props.history.push('/bots');
    } else if (e.key === 'prompt') {
        this.props.history.push('/general/prompt');
    } else if (e.key === 'history') {
        this.props.history.push('/general/history');
    } else if (e.key === 'azureSetting') {
        this.props.history.push('/general/azure-setting');
    } else if (e.key === 'role') {
        this.props.history.push('/system/role');
    } else if (e.key === 'user') {
        this.props.history.push('/system/user');
    }

    if (this.props.smallScreen) {
        this.props.setNavData({ collapsed: true });
    }
}

changePath() {
    this.props.setChatInfo({
        chatObj: {},
    });
    this.props.history.push('/chat-official');
}
closeNav(e) {
    if (e.target?.className === "navigation" && this.props.smallScreen) {
        this.props.setNavData({ collapsed: true });
    }
}
clickSubMenu(arr) {
    let lastArr = this.state.lastOpen;
    // console.log(lastArr)
    // 点开了广场页面
    if (arr.includes('square') && !lastArr.includes('square')) {
        this.props.history.push('/square')
    }
    this.setState({ lastOpen: arr })
}

changeSearch(e) {
    this.setState({ search: e.target.value })
}

enterToSearch(e) {
    if (e.keyCode === 13) {
        // fetch
    }
}

  render() {
    const { collapsed, smallScreen, moveToOpen, t, rights, theme } = this.props;
    const menuArr = [
      rights.kb && {
        key: 'kb',
        icon: !!this.state.selectedKeys.includes("kb") && theme.icNavKB ? theme.icNavKB : <img src={ic_kb} alt=''></img>,
        label: t('nav.知识库管理'),
      },
      rights.bot && {
        key: 'bot',
        icon: !!this.state.selectedKeys.includes("bot") && theme.icNavBot ? theme.icNavBot : <img src={ic_bot} alt=''></img>,
        label: t('nav.Bot管理'),
      },
      (rights.prompt || rights.history || rights.setting) && {
        key: 'general',
        icon: !!this.state.selectedKeys.includes("general") && theme.icNavGeneral ? theme.icNavGeneral : <img src={ic_general} alt=''></img>,
        label: t('nav.通用管理'),
        children: [
          rights.prompt && {
            key: 'prompt',
            label: t('nav.提示词管理'),
          },
          rights.history && {
            key: 'history',
            label: t('nav.对话历史记录管理'),
          },
          rights.setting && {
            key: 'azureSetting',
            label: t('nav.Azure Open AI 模型配置'),
          }
        ]
      },
      (rights.role || rights.user) && {
        key: 'rights',
        icon: !!this.state.selectedKeys.includes("rights") && theme.icNavRights ? theme.icNavRights : <img src={ic_rights} alt=''></img>,
        label: t('nav.权限管理'),
        children: [
          rights.role && {
            key: 'role',
            label: t('nav.角色管理'),
          },
          rights.user &&{
            key: 'user',
            label: t('nav.用户管理'),
          }
        ]
      }
    ]
    return (
      <div className={`navigation ${theme.themeName}`} open={!collapsed} onClick={(e)=>{ this.closeNav(e); }}>
        <Sider
          trigger={null}
          breakpoint='xl'
          onBreakpoint={(broken) => {
            this.props.setNavData({collapsed: broken, smallScreen: broken});
          }}
          width={'15.625rem'}
          collapsedWidth={0}
          collapsible
          collapsed={collapsed}
          onMouseEnter={()=>{
            if (smallScreen) return;
            this.props.setNavData({collapsed: false})
          }}
          onMouseLeave={(e)=>{
            if (smallScreen || !moveToOpen) return;
            this.props.setNavData({collapsed: true})
          }}
          style={{ background: "var(--nav-bg)" }}
            >
            <div className={collapsed ? (theme.logo_box + ' collapsed') : theme.logo_box}>
            <div className='icon_box' onClick={()=>{ this.changePath() }}>
                        {/*<img src={logo}  className='logo' alt=''></img>*/}
                <img id='bt_img_logo_top_left' src={theme.logo_top_left} className='logo' alt='' />
            </div>
            {/* <CloseOutlined className='nav_close'/> */}
            {React.createElement(collapsed || moveToOpen ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({collapsed: false, moveToOpen: false})
                  return
                }
                this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
              },
            })}
          </div>
          {/* <ChatList {...this.props} official={true}></ChatList> */}
          {/* <HeaderUsage {...this.props} showWidth={1024}></HeaderUsage> */}
          <Menu
            theme="dark"
            mode="inline"
            // defaultSelectedKeys={}
            defaultOpenKeys={['kb']}
            selectedKeys={this.state.selectedKeys}
            style={{ fontSize: ".9375rem", color: "var(--nav-text)", background: "var(--nav-bg)" }}
            onClick={(e)=>{ this.changeNavPath(e) }}
            onOpenChange={(e)=>{ this.clickSubMenu(e) }}
            items={menuArr}
          />
          <div className='nav_power'>
            {t("nav.Power 1")} <a href="https://www.novagpt.ai/" target="_blank" rel="noopener noreferrer">{t("nav.Novatech")}</a> {t("nav.Power 2")}
          </div>
        </Sider>
      </div> 
    )
  }
}

export default NavigationBar;