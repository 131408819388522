import React from 'react';
import { combineReducers } from 'redux';
import { SET_NAV_DATA, SET_USER_DATA, SET_PLAN_DATA, USER_LOG_OUT, SET_USAGE, SET_CHECK_USAGE, SET_CHAT_INFO, SET_HEADER_TITLE, SET_THEME_DATA } from './actionTypes.js'
import themeLIst from '../app/theme/themeLIst';

const initialState = {
  collapsed: false, 
  smallScreen: false,
  moveToOpen: false,
  user: {
    name: ""
  },
  plan: {
    type: 0, //0: 免费会员, 1: 初级，2：高级
    chatBot: 1, // 免费：1个      
    fileNum: 1, // 免费：1个
    fileSize: 2*1024*1024, // 免费：2M
    message: 6, // 每日会话数量 接口判断
    characters: 100000, // 回答字符数
    api: false, // 是否允许调用接口
    embed: false, // 是否允许嵌入
    default: true, // 是否是默认的 设置过后则无该字段
  },
  used: {
    type: "GPT"
  },
  chatInfo: {
    chatObj: {},
    promptsLibOpen: false,
  },
  headerTitle: [
    // {
    //   title: "Home",
    //   path: "/home",
    // },
    // {
    //   title: "Chat",
    //   path: "/chat",
    // },
  ],
  theme: {
    language: 'en',
  },
  rights: { // 0: 无权限，1：读写 2：只读
    default: true, // 是否是默认的 设置过后则无该字段
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAV_DATA: {
      // console.log("SET_NAV_DATA", action.navData);
      if (action.navData.collapsed !== undefined) {
        state.collapsed = action.navData.collapsed;
      }
      if (action.navData.smallScreen !== undefined) {
        state.smallScreen = action.navData.smallScreen;
      }
      if (action.navData.moveToOpen !== undefined) {
        state.moveToOpen = action.navData.moveToOpen;
      }
      return Object.assign({}, state)
    }
    case SET_USER_DATA: {
      state.user = action.userData;
      // console.log(state.userInfo)
      let role = action.userData?.role;
      if (role === undefined) {
        let obj = {
          kb: 2,
          bot: 1,
          prompt: 1,
          history: 2,
          setting: 0,
          role: 0,
          user: 0,
        }
        state.rights = obj;
    }
      else if (role.includes('超级管理员')) {
        let obj = {
          kb: 1,
          bot: 1,
          prompt: 1,
          history: 1,
          setting: 1,
          role: 1,
          user: 1,
        }
        state.rights = obj;
      } else if (role.includes('系统管理员')) {
        let obj = {
          kb: 1,
          bot: 1,
          prompt: 0,
          history: 1,
          setting: 1,
          role: 2,
          user: 2,
        }
        state.rights = obj;
      } else if (role.includes('KB管理员')) {
        let obj = {
          kb: 1,
          bot: 2,
          prompt: 0,
          history: 2,
          setting: 0,
          role: 0,
          user: 0,
        }
        state.rights = obj;
      } else if (role.includes('Bot管理员')) {
        let obj = {
          kb: 0,
          bot: 1,
          prompt: 0,
          history: 0,
          setting: 0,
          role: 0,
          user: 0,
        }
        state.rights = obj;
      }
      return Object.assign({}, state)
    }
    case SET_PLAN_DATA: {
      // state.plan = action.planData;
      switch (action.planData) {
        case 0: { // 免费
            state.plan = {
            type: 0,
            chatBot: 1,
            fileNum: 1,
            fileSize: 2*1024*1024,
            message: 6,
            characters: 100000,
            api: false,
            embed: false,
            }
            break;
        }
        case 1: { // 初级
            state.plan = {
            type: 1,
            chatBot: 3,
            fileNum: 10,
            fileSize: 10*1024*1024,
            message: 600,
            characters: 1000000,
            api: true,
            embed: true,
            }
            break;
        }
        case 2: { // 高级
            state.plan = {
            type: 1,
            chatBot: 10,
            fileNum: 20,
            fileSize: 100*1024*1024,
            message: 2000,
            characters: 10000000,
            api: true,
            embed: true,
            }
            break;
        }
        case 3: { // 超级
            state.plan = {
                type: 1,
                chatBot: 15,
                fileNum: 100,
                fileSize: 100 * 1024 * 1024,
                message: 20000,
                characters: 100000000,
                api: true,
                embed: true,
            }
            break;
        }
        default: break;
      }
      return Object.assign({}, state)
    }
    case USER_LOG_OUT: {
      window.location.href = `${window.location.origin}/authentication/logout`;
      return Object.assign({}, state);
      // 测试环境不登出
      if (window.location.hostname === "localhost") {
        window.location.href = `http://localhost:3000/#/login?uid=${localStorage.getItem('uid')}`;
        return;
      }
      console.log("++++USER_LOG_OUT++++");
      document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      localStorage.clear();
      if (action.logData === "redirect") {
        window.location.href = `https://www.novagpt.ai/login?redirect_uri=${window.location.href}`;
      } else {
        window.location.href = 'https://www.novagpt.ai/logout';
      }
      return Object.assign({}, state)
    }
    case SET_USAGE: {
      if (action.usageData?.type) {
        state.used.type = action.usageData.type;
      } else if (action.usageData?.usage) {
        state.used.usage = action.usageData.usage;
      }
      return Object.assign({}, state)
    }
    case SET_CHECK_USAGE: {
      state.checkUsage = action.callback;
      return Object.assign({}, state)
    }
    case SET_CHAT_INFO: {
      Object.keys(action.chatInfo).forEach(key => {
        state.chatInfo[key] = action.chatInfo[key];
      })
      return Object.assign({}, state)
    }
    case SET_HEADER_TITLE: {
      if (action.method === 'set' && action.headerTitle) {
        state.headerTitle = action.headerTitle;
      } else if (action.method === 'push' && action.headerTitle) {
        state.headerTitle.push(action.headerTitle);
      } else if (action.method === 'pop') {
        state.headerTitle.pop();
      }
      return Object.assign({}, state)
    }
    case SET_THEME_DATA: {
      let themeObj;
      if (typeof action.themeData === 'string') {
        state.theme.themeName = action.themeData;
        themeObj = themeLIst[action.themeData];
      } else {
        themeObj = action.themeData;
      }
      // console.log("themeObj", themeObj);
      Object.keys(themeObj).forEach(key => {
        if (key === 'botIcon') {
          state.theme[key] = <svg dangerouslySetInnerHTML={{ __html: themeObj[key].path}} width={20} height={20} viewBox={themeObj[key].viewBox} fill={themeObj['--primary-color']}></svg>;
          return;
        }
        if (key == 'sendIcon') {
          state.theme[key] = <svg dangerouslySetInnerHTML={{ __html: themeObj[key].path}} width={20} height={20} viewBox={themeObj[key].viewBox} fill={themeObj['--sub-color']}></svg>;
          return;
        }
        if (key.startsWith('ic')) {
          state.theme[key] = <svg dangerouslySetInnerHTML={{ __html: themeObj[key].path}} width={24} height={24} viewBox={themeObj[key].viewBox} fill={themeObj['--primary-color']}></svg>;
          return;
        }
        if (key.startsWith('--')) {
          document.documentElement.style.setProperty(key, themeObj[key]);
        }
        state.theme[key] = themeObj[key];
      })
    }
    default: {
      return state
    }
  }
}

const rootReducer = combineReducers({ reducer })

export default rootReducer
