import icon from "./icon";

let themeList = {
    "mce": {
        page_title: 'Ask Me Licensing',
        navIcon: '/bt/mce/logo.svg?v=1',
        logo_box: 'logo_box',
        logo_top_left:'/bt/mce/logo_top_left.svg?v=1',
        botIcon: icon.botIcWhite,
        sendIcon: icon.sendBtn,
        icNavKB: icon.icNavKB,
        icNavBot: icon.icNavBot,
        icNavGeneral: icon.icNavGeneral,
        icNavRights: icon.icNavRights,
        icBotEapi: icon.icBotEapi,
        icBotEchat: icon.icBotEchat,
        icBotElink: icon.icBotElink,
        icHistoryMsg: icon.icHistoryMsg,
        '--primary-color':'#137AD1',
        '--primary-color-30':'rgba(19, 122, 209, .30)',
        '--primary-color-18':'rgba(19, 122, 209, .18)',
        '--primary-color-70':'rgba(19, 122, 209, .7)',
        '--secondary-color': '#137AD1',
        '--sub-color':'#002855',
        '--gradient-bg':'linear-gradient(101deg, #135FD1 0%, #00ADEF 100%)',
        '--nav-bg':'#102343',
        '--nav-text':'#6F6B7D',
        '--nav-text-hover':'#FFFFFF',
        '--power-color':'rgba(255, 255, 255, .38)',
        '--power-link-color':'rgba(255, 255, 255, .88)',
        '--avater-bg':'#EBF4FF',
        '--avater-text':'#002855',
        '--card-bg':'linear-gradient(123deg, #1976D2 0%, #4DB4F4 100%)',
        '--tag-bg':'rgba(255, 174, 34,, 0.08)',
        '--tag-text':'#FBBC09',
    },
    "apm": {
        page_title: 'APM KB',
        navIcon: '/bt/apm/logo.svg',
        logo_box: 'logo_box_apm',
        logo_top_left: '/bt/apm/logo_top_left.svg',
        botIcon: icon.botIcWhite,
        sendIcon: icon.sendBtn,
        icNavKB: icon.icNavKB,
        icNavBot: icon.icNavBot,
        icNavGeneral: icon.icNavGeneral,
        icNavRights: icon.icNavRights,
        icBotEapi: icon.icBotEapi,
        icBotEchat: icon.icBotEchat,
        icBotElink: icon.icBotElink,
        icHistoryMsg: icon.icHistoryMsg,
        '--primary-color': '#D2AD4D',
        '--primary-color-30': 'rgba(210, 173, 77, .30)',
        '--primary-color-18': 'rgba(210, 173, 77, .18)',
        '--primary-color-70': 'rgba(210, 173, 77, .7)',
        '--secondary-color': '#D2AD4D',
        '--sub-color': '#A77455',
        '--gradient-bg': 'linear-gradient(101deg, #135FD1 0%, #00ADEF 100%)',
        '--nav-bg': '#0B142B',
        '--nav-text': '#6F6B7D',
        '--nav-text-hover': '#FFFFFF',
        '--power-color': 'rgba(255, 255, 255, .38)',
        '--power-link-color': 'rgba(255, 255, 255, .88)',
        '--avater-bg': '#F9ECD8',
        '--avater-text': '#A77455',
        '--card-bg': 'linear-gradient(123deg, #1976D2 0%, #4DB4F4 100%)',
        '--tag-bg': 'rgba(255, 174, 34,, 0.08)',
        '--tag-text': '#FBBC09',
    },
    "jc": {
        page_title: '集萃知识库',
        navIcon: '/bt/jc/logo.png',
        logo_box: 'logo_box',
        logo_top_left: '/bt/jc/logo_top_left.png',
        botIcon: icon.botIcWhite,
        sendIcon: icon.sendBtn,
        icNavKB: icon.icNavKB,
        icNavBot: icon.icNavBot,
        icNavGeneral: icon.icNavGeneral,
        icNavRights: icon.icNavRights,
        icBotEapi: icon.icBotEapi,
        icBotEchat: icon.icBotEchat,
        icBotElink: icon.icBotElink,
        icHistoryMsg: icon.icHistoryMsg,
        '--primary-color': '#2EA39C',
        '--primary-color-30': 'rgba(46, 163, 156, .30)',
        '--primary-color-18': 'rgba(46, 163, 156, .18)',
        '--primary-color-70': 'rgba(46, 163, 156, .7)',
        '--secondary-color': '#2EA39C',
        '--sub-color': '#047E75',
        '--gradient-bg': 'linear-gradient(101deg, #135FD1 0%, #00ADEF 100%)',
        '--nav-bg': '#D1DADF',
        '--nav-text': '#6F6B7D',
        '--nav-text-hover': '#047E75',
        '--power-color': '#999999',
        '--power-link-color': 'rgba(50, 50, 50, .88)',
        '--avater-bg': '#F9ECD8',
        '--avater-text': '#047E75',
        '--card-bg': 'linear-gradient(123deg, #1976D2 0%, #4DB4F4 100%)',
        '--tag-bg': 'rgba(255, 174, 34,, 0.08)',
        '--tag-text': '#FBBC09',
    },
}

export default themeList;